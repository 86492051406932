<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <statistic-card-with-area-chart
          v-if="data.subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(totalRows)"
          statistic-title="Users"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <!-- <b-button
                variant="success"
                class="mr-50"
                v-b-modal.quick-add
              >
              quick add
              </b-button>
              <b-button
              variant="primary"
              class="mr-50"
              v-b-modal.add-multiple
            >
            Add multiple
            </b-button> -->
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <label>Entries</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block invoice-filter-select ml-50 mr-50"
                  />
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="users"
            :fields="fieldsUser"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(number)="data">
              <strong class="animal1">{{ data.value }}</strong>
              
            </template>

            <template #cell(animals)="data">
              <strong><span  v-if="!data.value">0</span>{{ data.value }}/11</strong>
            </template>

            <template #cell(status)="data">
              <b-badge
                :variant="status[1][getKeyByValue(status[0], data.value)]"
              >
                {{ data.value }}
              </b-badge>
            </template>

            <!-- Formatting Date for createdAt and updatedAt -->
            <template #cell(createdAt)="data">
              {{ new Date(data.value).toLocaleDateString() }}
            </template>

            <template #cell(updatedAt)="data">
              {{ new Date(data.value).toLocaleDateString() }}
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-tooltip
                  title="action"
                  :target="`invoice-row-${data.item.id}-preview-icon`"
                />

                <!-- Dropdown -->
                <b-dropdown variant="link" toggle-class="p-0" no-caret right>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.add-multiple
                    @click="GetDataQ1(data.item.id)"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Question 1</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-b-modal.add-multiple2
                    @click="GetDataQ2(data.item.id)"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Question 2</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-b-modal.modal-sm
                    @click="GetDataTree(data.item.id)"
                  >
                    <feather-icon icon="ArchiveIcon" />
                    <span class="align-middle ml-50">Tree</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmDelete(data.item.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
        <br />
      </b-col>

      <b-modal
        id="add-multiple"
        ok-only
        centered
        title="เเบบประเมินที่ 1"
        ok-variant="success"
        ok-title="Close"
      >
        <b-form-group label="หนูอายุเท่าไหร่?" label-for="No1">
          <b-form-input
            id="No1"
            v-model="answers1.question1"
            disabled
            placeholder="ไม่มีคำตอบ"
          />
        </b-form-group>

        <b-form-group label="หนูมากับใคร?" label-for="No2">
          <b-form-input
            id="No2"
            v-model="answers1.question2"
            placeholder="ไม่มีคำตอบ"
            disabled
          />
        </b-form-group>
        <b-form-group
          label='เมื่อนึกถึงคำว่า"สิ่งเเวดล้อม"หนูนึกถึงอะไร?'
          label-for="No3"
        >
          <b-form-input
            id="No3"
            v-model="answers1.question3"
            disabled
            placeholder="ไม่มีคำตอบ"
          />
        </b-form-group>
        <b-form-group label="สิ่งเเวดล้อมรอบตัวหนูคืออะไร?" label-for="No4">
          <b-form-input
            id="no4"
            v-model="answers1.question4"
            placeholder="ไม่มีคำตอบ"
            disabled
          />
        </b-form-group>
      </b-modal>

      <b-modal
        id="add-multiple2"
        ok-only
        centered
        title="เเบบประเมินที่ 2"
        ok-variant="success"
        ok-title="Close"
      >
        <b-form-group label="ชื่อเล่นของหนู?" label-for="no1">
          <b-form-input
              id="no1"
              v-model="answers2.question1"
              disabled
              placeholder="ไม่มีคำตอบ"
          />
        </b-form-group>

        <b-form-group label="อายุ?" label-for="no2">
          <b-form-input
              id="no2"
              v-model="answers2.question2"
              placeholder="ไม่มีคำตอบ"
              disabled
          />
        </b-form-group>
        <b-form-group
            label="หนูรู้สึกอย่างไรกับเกมที่ได้เล่นไป?"
            label-for="no3"
        >
          <b-form-input
              id="no3"
              v-model="answers2.question3"
              disabled
              placeholder="ไม่มีคำตอบ"
          />
        </b-form-group>
        <b-form-group
            label="หนูชอบเกมไหนมากกว่ากัน ระหว่าง ตามหาสัตว์ กับ ปลูกต้นไม้?"
            label-for="no4"
        >
          <b-form-input
              id="no4"
              v-model="answers2.question4"
              placeholder="ไม่มีคำตอบ"
              disabled
          />
        </b-form-group>

        <b-form-group
            label='หนูอยากเห็น"สิ่งแวดล้อม" ในอนาคต เป็นอย่างไร?'
            label-for="no5"
        >
          <b-form-input
              id="no5"
              v-model="answers2.question5"
              placeholder="ไม่มีคำตอบ"
              disabled
          />
        </b-form-group>

        <b-form-group
            label="ที่อยู่ หรือ โรงเรียนที่ศึกษาอยู่ (กรณีอยากให้ส่งรางวัลไปให้ที่โรงเรียนหรือที่บ้าน)"
            label-for="no6"
        >
          <b-form-input
              id="no6"
              v-model="answers2.question6"
              placeholder="ไม่มีคำตอบ"
              disabled
          />
        </b-form-group>
      </b-modal>

      <b-modal
      id="modal-sm"
      cancel-variant="secondary"
      ok-only
      ok-title="Close"
      centered
      size="sm"
      title="ต้นไม้"
    >

        <div align="center" >
          <div class="tree-item" >
            <div v-if="!flowers.pot" class="textTree">ไม่พบต้นไม้</div>
            <img v-if="flowers && flowers.pot == 1 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Pot_1.png">
            <img v-if="flowers && flowers.pot == 2 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Pot_2.png">
            <img v-if="flowers && flowers.pot == 3 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Pot_3.png">
            <img v-if="flowers && flowers.pot == 4 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Pot_4.png">
            <img v-if="flowers && flowers.pot == 5 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Pot_5.png">

            <img v-if="flowers && flowers.flower == 1 "  class="tree-game flower " data-wow-duration="1.5s" src="../../assets/images/tree/Flower_1.png">
            <img v-if="flowers && flowers.flower == 2 "  class="tree-game flower " data-wow-duration="1.5s" src="../../assets/images/tree/Flower_2.png">
            <img v-if="flowers && flowers.flower == 3 "  class="tree-game flower " data-wow-duration="1.5s" src="../../assets/images/tree/Flower_3.png">

            <img v-if="flowers && flowers.dirt == 1 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Dirt_1.png">
            <img v-if="flowers && flowers.dirt == 2 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Dirt_2.png">

            <img v-if="flowers && flowers.trunk == 1 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/trunk1.png">
            <img v-if="flowers && flowers.trunk == 2 "  class="tree-game " data-wow-duration="1.5s" src="../../assets/images/tree/Trunk2.png">
          </div>
        </div>

    </b-modal>

      <b-modal
        id="quick-add"
        title="Add a single QR code"
        ok-title="submit"
        @ok="AddQuickQrCode(SelectEdit.type)"
        cancel-variant="outline-secondary"
      >
        <b-form>
          <b-form-group label="Type Card" label-for="vue-select">
            <v-select
              id="vue-select"
              v-model="SelectEdit.type"
              :options="TypesCard"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import axios from 'axios'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'

import AnalyticsCongratulation from '../../views/dashboard/analytics/AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from '../../views/dashboard/analytics/AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from '../../views/dashboard/analytics/AnalyticsSupportTracker.vue'
import AnalyticsTimeline from '../../views/dashboard/analytics/AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from '../../views/dashboard/analytics/AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from '../../views/dashboard/analytics/AnalyticsAppDesign.vue'
import AnalyticsTable from '../../views/dashboard/analytics/TableAnalytics.vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardCode,
    BImg,
    BDropdownItem,
    BDropdown,
    BTooltip,
    BModal,
    VBModal,
    vSelect,
    StatisticCardWithAreaChart,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
    AnalyticsTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      dailog: false,
      data: {},
      filterOn: [
        'number',
        '_id',
        'data',
        'type',
        'status',
        'createdAt',
        'updatedAt',
      ],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fieldsUser: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'animals', label: 'Animals' },
        { key: 'createdAt', label: 'Date' },
        { key: 'actions', label: 'info' },
      ],
      fields: [
        { key: '_id', label: 'QR Code ID' },
        { key: 'data', label: 'Data' },

        { key: 'createdAt', label: 'Date' },
        { key: 'updatedAt', label: 'Updated At' },
      ],
      items: [{}],
      users: [{}],
      answers1: [
        { question1: '', question2: '', question3: '', question4: '' },
      ],
      answers2: [
        {
          question1: '',
          question2: '',
          question3: '',
          question4: '',
          question5: '',
          question6: '',
        },
      ],

      flowers: {},
      statusList: [
        'Not Printed',
        'Printed.',
        'Used',
        'Type1',
        'Type2',
        'Type3',
        'Type4',
      ],
      status: [
        {
          1: 'Not Printed',
          2: 'Printed.',
          3: 'Used',
        },
        {
          1: 'light-secondary',
          2: 'light-success',
          3: 'light-danger',
        },
      ],
      Types: [
        {
          1: 'Type1',
          2: 'Type2',
          3: 'Type3',
          4: 'Type4',
        },
        {
          1: require('@/assets/images/avatars/type1.png'),
          2: require('@/assets/images/avatars/type2.png'),
          3: require('@/assets/images/avatars/type3.png'),
          4: require('@/assets/images/avatars/type4.png'),
        },
      ],

      TypesCard: ['Type1', 'Type2', 'Type3', 'Type4'],
      StatusCard: ['Not Printed', 'Printed.', 'Used'],
      SelectEdit: {
        id: null,
        number: null,
        type: 'Type1',
        status: null,
        quantity: null,
      },
      SelectId: '',
      totalRows: 0,
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data').then((response) => {
      this.data = response.data
    })
  },
  watch: {
    answers1() {
      this.dailog = this.dailog ? true : false
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }))
    },
  },
  async mounted() {
    try {
      const response = await axios.get('https://api.waedlom.com/api/user')

      this.users = response.data.map((users) => ({
        ...users,
      }))
      console.log(this.user)
      this.totalRows = this.users.length
    } catch (error) {
      console.error('Error fetching QR codes:', error)
    }
  },
  methods: {
    kFormatter,
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value)
    },

    // comfirm button c

    async AddQuickQrCode(type) {
      try {
        await axios.post(`http://localhost:3000/api/qrcodes/`, { type })
        // Remove the deleted item from the items array
        this.success()
        this.get()
        this.items = this.items.filter((item) => item._id !== id)
      } catch (error) {
        console.error('Error deleting QR code:', error)
      }
    },

    async GetDataQ1(id) {
      this.answers1 = {}

      try {
        const response = await axios.get(
          `https://api.waedlom.com/api/user/` + id,
        )
        console.log(id)
        if (response.data && response.data.answers1.question1) {
          console.log('get')
          this.answers1 = response.data.answers1
        }
      } catch (error) {
        console.error('Error fetching user name:', error)
      }
    },

    async GetDataQ2(id) {
      try {
        const response = await axios.get(
          `https://api.waedlom.com/api/user/` + id,
        )
        console.log(id)
        if (response.data && response.data.answers2) {
          this.answers2 = response.data.answers2

          console.log(this.answers2.question2)
        }
      } catch (error) {
        console.error('Error fetching user name:', error)
      }
    },

    async GetDataTree(id) {

      this.flowers = {};

      try {
        const response = await axios.get(
          `https://api.waedlom.com/api/user/` + id,
        )
        console.log(id)
        if (response.data && response.data.flowers) {
          this.flowers = response.data.flowers
          console.warn(this.flowers);
        }
      } catch (error) {
        console.error('Error fetching user name:', error)
      }
    },

    async AddMultipleQrCode(type, quantity) {
      try {
        await axios.post(`http://localhost:3000/api/qrcodes/typed`, {
          type,
          quantity,
        })
        this.success()
        this.get()
        // Remove the deleted item from the items array
        this.items = this.items.filter((item) => item._id !== id)
      } catch (error) {
        console.error('Error deleting QR code:', error)
      }
    },

    success() {
      this.$swal({
        title: 'เรียบร้อย!',
        text: 'การดำเนินการเสร็จสิ้น!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    async get() {
      try {
        const response = await axios.get('https://api.waedlom.com/api/user')

        this.users = response.data.map((users) => ({
          ...users,
        }))
        console.log(this.user)
        this.totalRows = this.users.length
      } catch (error) {
        console.error('Error fetching QR codes:', error)
      }
    },
    async deleteQrCode(id) {
      try {
        await axios.delete(`https://api.waedlom.com/api/users/` + id)
        // Remove the deleted item from the items array
      } catch (error) {
        console.error('Error deleting QR code:', error)
      }
    },

    // comfirm button c
    confirmDelete(id) {
      this.$swal({
        title: 'คุณเเน่ใจหรือไม่?',
        text: 'คุณต้องการจะลบรายชื่อนี้ออกจะระบบอย่างถาวร!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'รายชื่อถูกลบอย่างถาวรเเล้ว.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.deleteQrCode(id)
          this.get()
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'ยกเลิก',
            text: 'ยกเลิกการทำรายการนี้เเล้ว :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.tree-item{
  width: fit-content;
  height: fit-content;
  position: relative;
  width: 20rem;
  height: 20rem;
}

.tree-game{
  position: absolute;
  width: 25rem !important;
  bottom: -2rem;
  left: -2rem;
  transform-origin: 0 0;
  z-index: 1;
}
.textTree{

padding-top: 50%;
font-size: 1rem;
font-weight: 600;
color:rgb(98, 167, 115)
}

.tree-object {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
